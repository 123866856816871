import state from './bankAccountState';
import mutations from './bankAccountMutations';
import actions from './bankAccountActions';
import getters from "./bankAccountGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}