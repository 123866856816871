import api from '@/libs/axios'

export default {
  getVats ({ commit }) {
    return new Promise((resolve, reject) => {
      api.get('/vats')
        .then(response => {
          commit('SET_VATS', response.data)
          resolve(response)
        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },

}