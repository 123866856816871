export default {
  SET_TICKETS (state, tickets) {
    state.tickets = tickets
  },
  ADD_TICKET (state, ticket) {
    state.tickets.push(ticket)
  },
  UPDATE_TICKET(state, ticket) {
    let index = state.tickets.findIndex(i => i.id === ticket.id)
    state.tickets.splice(index, 1, ticket)
  },
  REMOVE_TICKET (state, ticket) {
    state.tickets = state.tickets.filter(item => item.id != ticket.id)

    // let index = state.tickets.findIndex(i => i.id === ticket.id)
    // state.tickets.splice(index, 1)
  },
}