import i18n from '@/libs/i18n'

const translateTerms = (order) => {
  order.paymentMethod.name = i18n.t(order.paymentMethod.name)
  order.paymentTerm.term = i18n.t(order.paymentTerm.term)
}

export default {
  SET_ORDERS (state, orders) {
    orders.forEach(o => {
      translateTerms(o)
    })
    state.orders = orders
  },
  ADD_ORDER (state, order) {
    translateTerms(order)

    state.orders.push(order)
  },
  UPDATE_ORDER(state, order) {
    translateTerms(order)

    let index = state.orders.findIndex(i => i.id == order.id)
    state.orders.splice(index, 1, JSON.parse(JSON.stringify(order)))
  },
  REMOVE_ORDER (state, order) {
    state.orders = state.orders.filter(item => item.id != order.id)

    // let index = state.orders.findIndex(i => i.id === order.id)
    // state.orders.splice(index, 1)
  },
}