import Vue from 'vue'
import axios from 'axios'

const accessToken = localStorage.getItem('accessToken')
// const apiURL = 'http://192.168.1.11:8000/'
// const apiURL = 'https://api.erpv2.hmt-france.com/'
const apiURL = 'https://api.erp-coopti.hmt-france.com/'

const api = axios.create({
  // You can add your headers here
  // ================================
  baseURL: apiURL,
  // timeout: 1000,
  headers: { 'Authorization': 'Bearer ' + accessToken }
})

Vue.prototype.$http = api
export default api