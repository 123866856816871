export default [
  // Banking flows
  {
    path: '/bankingflows',
    name: 'BankingFlows',
    component: () => import('@/views/accounting/bankingFlows/BankingFlows.vue'),
    meta: {
      resource: 'BankingFlows',
      action: 'manage',
    }
  },
  // Customer receivable
  {
    path: '/customerreceivable',
    name: 'CustomerReceivable',
    component: () => import('@/views/accounting/customerReceivable/CustomerReceivable.vue'),
    meta: {
      resource: 'CustomerReceivable',
      action: 'manage',
    }
  },
  // Supplier receivable
  {
    path: '/supplierreceivable',
    name: 'SupplierReceivable',
    component: () => import('@/views/accounting/supplierReceivable/SupplierReceivable.vue'),
    meta: {
      resource: 'SupplierReceivable',
      action: 'manage',
    }
  }
]
