import i18n from '@/libs/i18n'
import router from '@/router'
import moment from 'moment'
import store from '@/store'
import { computed } from '@vue/composition-api'
import { capitalize } from './filter'

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

export const initials = text => {
  let initials = JSON.parse(JSON.stringify(text)).split(' ')

  if (initials.length > 1) {
    initials = initials.shift().charAt(0) + initials.pop().charAt(0)
  } else {
    initials = name.substring(0, 2)
  }

  return initials.toUpperCase()
}

// export const getDate = () => {
//   if (i18n.locale == 'fr') {
//     return moment().format('DD/MM/YYYY')
//   } else {
//     return moment().format('YYYY-MM-DD')
//   }
// }
//
// export const getDateTime = () => {
//   if (i18n.locale == 'fr') {
//     return moment().format('DD/MM/YYYY HH:mm')
//   } else {
//     return moment().format('YYYY-MM-DD HH:mm')
//   }
// }

export const isUserLoggedIn = () => {
  return localStorage.getItem('userData')
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  // if (userRole === 'client') return { name: 'access-control' }
  return { name: 'auth-login' }
}

export const comparableString = (item) => {
  return item.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const sortCompare = key => (a, b) => {
  if (a[key] == null || b[key] == null) {
    return 0;
  }

  const fieldA = comparableString(a[key])
  const fieldB = comparableString(b[key])

  return fieldA === fieldB ? 0 : fieldA < fieldB ? -1 : 1
}

export const getRoute = () => {
  return router.history.current
}

export const getCurrentFinancialYear = () => {
  let now = moment()
  if (now > moment(now.format('YYYY') + '-07-01')) {
    return {
      start: now.format('YYYY') + '-07-01',
      end: (parseInt(now.format('YYYY')) + 1) + '-06-30'
    }
  } else {
    return {
      start: (parseInt(now.format('YYYY')) - 1) + '-07-01',
      end: now.format('YYYY') + '-06-30'
    }
  }
}

export const eventToCalendarEvent = (event) => {
  let calendarsName = [event.logs[0].by.firstName + ' ' + event.logs[0].by.name]
  event.additionalMembers.forEach(am => {
    calendarsName.push(am.firstName + ' ' +am.name)
  })

  let color = 'secondary'
  if (getUserData().id == event.logs[0].by.id) {
    color = 'primary'
  }

  calendarsName.forEach(cn => {
    if (!store.getters['calendar/getCalendars'].some(c => c.name == cn)) {
      store.commit('calendar/ADD_CALENDAR', {
        name: cn,
        color: color
      })
    }
  })

  // if (!store.getters['calendar/getCalendars'].some(c => calendarsName.includes(c.name))) {
  //   store.commit('calendar/ADD_CALENDAR', {
  //     name: c.name,
  //     color: color
  //   })
  // }

  return {
    id: event.id,
    title: event.title!=null?event.title:(event.type=='holiday'?i18n.t('Holidays'):''),
    start: event.startDate,
    end: event.endDate,
    allDay: event.isAllDay,
    editable: (!event.isValidated && event.type != 'holiday' && color != 'secondary'),
    extendedProps: {
      calendars: calendarsName,
      color: color,
      entity: event,
      icons: getEventIcons(event),
      // secondaryIcon: (event.type == 'holiday' && event.isValidated == null) ? 'stopwatch' : null
    }
  }
}

export const getEventIcons = (event) => {
  let icons = []
  if (event.workflow == null && event.type != 'holiday') {
    icons.push('exclamation-triangle')
  }

  if (event.type == 'default') {
    // icons.push('user-cog')
  } else if (event.type == 'call') {
    icons.push('phone')
  } else if (event.type == 'visio') {
    icons.push('headset')
  } else if (event.type == 'site') {
    icons.push('briefcase')
  } else if (event.type == 'trip') {
    icons.push('suitcase-rolling')
  } else if (event.type == 'holiday') {
    icons.push('umbrella-beach')
    if (event.isValidated == null) {
      icons.push('stopwatch')
    }
  }



  return icons
}

export const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
export const setRepetitionDisplay = (repetition) => {
  if (
    typeof repetition != 'undefined' &&
    repetition != null &&
    'period' in repetition &&
    'quantity' in repetition
  ) {

    if (repetition.period == 'days') {
      // Days

      if (parseInt(repetition.quantity) == 1) {
        repetition._display = capitalize(i18n.t('allDays0'))
      } else if (parseInt(repetition.quantity) > 1) {
        repetition._display = capitalize(i18n.t('allDays', { days: repetition.quantity }))
      } else {
        delete repetition._display
      }
    } else if (repetition.period == 'weeks') {
      // Weeks

      repetition.days.sort((a, b) => (days.indexOf(a) > days.indexOf(b)) ? 1 : ((days.indexOf(b) > days.indexOf(a)) ? -1 : 0))

      let displayDays = []
      repetition.days.forEach(d => {
        displayDays.push(i18n.t(d))
      })
      displayDays = displayDays.join(', ')
      // console.log(displayDays)
      if (parseInt(repetition.quantity) == 1) {
        repetition._display = capitalize(i18n.t('allWeeks0'))
        repetition._display += '<br /><small>' + capitalize(displayDays) + '</small>'
      } else if (parseInt(repetition.quantity) > 1) {
        repetition._display = capitalize(i18n.t('allWeeks', { weeks: repetition.quantity }))
        repetition._display += '<br /><small>' + capitalize(displayDays) + '</small>'
      } else {
        delete repetition._display
      }

    } else if (repetition.period == 'months') {
      if (parseInt(repetition.quantity) == 1) {
        repetition._display = capitalize(i18n.t('allMonths0'))
      } else if (parseInt(repetition.quantity) > 1) {
        repetition._display = capitalize(i18n.t('allMonths', { months: repetition.quantity }))
      } else {
        delete repetition._display
      }
    } else if (repetition.period == 'years') {
      if (parseInt(repetition.quantity) == 1) {
        repetition._display = capitalize(i18n.t('allYears0'))
      } else if (parseInt(repetition.quantity) > 1) {
        repetition._display = capitalize(i18n.t('allYears', { years: repetition.quantity }))
      } else {
        delete repetition._display
      }
    }
  }
}

export const displayPaymentStatus =  (paymentStatus) => {
  if (paymentStatus == 'Waiting for payment') {
    return {
      variant: 'info',
      value: i18n.t(paymentStatus)
    }
  } else if (paymentStatus == 'Paid') {
    return {
      variant: 'success',
      value: i18n.t(paymentStatus)
    }
  } else if (paymentStatus == 'Partial payment') {
    return {
      variant: 'warning',
      value: i18n.t(paymentStatus)
    }
  } else if (paymentStatus == 'Unpaid') {
    return {
      variant: 'danger',
      value: i18n.t(paymentStatus)
    }
  }
}
