import state from './bankOperationState';
import mutations from './bankOperationMutations';
import actions from './bankOperationActions';
import getters from "./bankOperationGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}