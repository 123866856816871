import state from './bankStatementState';
import mutations from './bankStatementMutations';
import actions from './bankStatementActions';
import getters from "./bankStatementGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}