import api from '@/libs/axios'
import { cleanEntityToSend } from '../../utils/form'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'
import i18n from '@/libs/i18n'

export default {
  getOffers ({ commit }) {
    return new Promise((resolve, reject) => {
      api.get('/offers')
        .then(response => {
          // console.log(JSON.stringify(response.data))
          commit('SET_OFFERS', response.data)
          resolve(response)
        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  async addOffer ({ commit, state }, offer) {
    return await cleanEntityToSend(offer, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/offers', clonedEntityToSend)
            .then(response => {
              commit('ADD_OFFER', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The offer has been ', { crud: i18n.t('createdE') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateOffer ({ commit, state }, offer) {
    return await cleanEntityToSend(offer, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.put('/offers/' + offer.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_OFFER', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The offer has been ', { crud: i18n.t('editedE') }),
                  variant: 'success'
                },
              })

              resolve(response)
              // resolve(JSON.parse(JSON.stringify(response)))
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeOffer ({ commit }, offer) {
    return new Promise((resolve, reject) => {
      api.delete('/offers/' + offer.id)
        .then(response => {
          commit('REMOVE_OFFER', offer)

          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The offer has been ', { crud: i18n.t('removedE') }),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },
  // async validateOffer ({ commit, state }, offer) {
  //   return new Promise((resolve, reject) => {
  //     api.put('/offers/' + offer.id + '/validate')
  //       .then(response => {
  //         commit('UPDATE_OFFER', response.data)
  //         Vue.$toast({
  //           component: ToastificationContent,
  //           props: {
  //             title: capitalize(i18n.t('success')),
  //             icon: 'check',
  //             text: i18n.t('The offer has been ', { crud: i18n.t('edited') }),
  //             variant: 'success'
  //           },
  //         })
  //
  //         resolve(response)
  //       })
  //       .catch(error => {
  //         console.log('Error', error)
  //         reject(error)
  //       })
  //   })
  // },
  async transformProductsToOutgoingInvoice ({ commit, state }, payload) {
    let offer = payload.offer
    let selectedProducts = payload.selectedProducts
    offer.toInvoice = selectedProducts

    return await cleanEntityToSend(offer, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.put('/offers/' + offer.id + '/transformToOutgoingInvoice', clonedEntityToSend)
            .then(response => {
              selectedProducts.forEach(p => p.outgoingInvoice = response.data.id)

              // commit('UPDATE_OFFER', response.data)
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The outgoingInvoice has been ', { crud: i18n.t('createdE') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  // printOfferQuote ({ commit }, offer) {
  //   return new Promise((resolve, reject) => {
  //     api.get('/offers/' + offer.id + '/quotePdf')
  //       .then(response => {
  //         let displayQuoteNumber = offer.billNumber + '-'
  //
  //         if (offer.version < 10) {
  //           displayQuoteNumber += ('00' + offer.version.toString())
  //         } else {
  //           if (offer.version < 100) {
  //             displayQuoteNumber += ('0' + offer.version.toString())
  //           } else {
  //             displayQuoteNumber += offer.version.toString()
  //           }
  //         }
  //
  //         let byteCharacters = atob(response.data)
  //         let byteNumbers = new Array(byteCharacters.length)
  //         for (var i = 0; i < byteCharacters.length; i++) {
  //           byteNumbers[i] = byteCharacters.charCodeAt(i)
  //         }
  //         let byteArray = new Uint8Array(byteNumbers)
  //         let blob = new Blob([byteArray], {
  //           type: 'application/pdf'
  //         })
  //
  //         let link = document.createElement('a')
  //         link.href = window.URL.createObjectURL(blob)
  //         link.download = capitalize(i18n.tc('quote')) + ' - ' + displayQuoteNumber + '.pdf'
  //         link.click()
  //         resolve(response)
  //       })
  //       .catch(err => {
  //         console.log('Error', err)
  //         reject(err)
  //       })
  //   })
  // },

}