import api from '@/libs/axios'
import { cleanEntityToSend } from '../../utils/form'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'
import i18n from '@/libs/i18n'

export default {
  getCompanies ({ commit }) {
    return new Promise((resolve, reject) => {
      api.get('/companies')
        .then(response => {
          // console.log(JSON.stringify(response.data))
          commit('SET_COMPANIES', response.data)
          resolve(response)
        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  async addCompany ({ commit, state }, company) {
    return await cleanEntityToSend(company, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/companies', clonedEntityToSend)
            .then(response => {
              commit('ADD_COMPANY', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The company has been ', { crud: i18n.t('createdE') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateCompany ({ commit, state }, company) {
    return await cleanEntityToSend(company, state.params)
      .then(async clonedEntityToSend => {

        return new Promise((resolve, reject) => {
          api.put('/companies/' + company.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_COMPANY', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The company has been ', { crud: i18n.t('editedE') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              // console.log('Error', error)
              if (error.response && error.response.status == 401) {
                Vue.$toast({
                  component: ToastificationContent,
                  props: {
                    title: i18n.t('UnableToUpdate'),
                    icon: 'times',
                    text: i18n.t('Changes are linked ton non-editable items'),
                    variant: 'danger'
                  },
                })
              }
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeCompany ({ commit }, company) {
    return new Promise((resolve, reject) => {
      api.delete('/companies/' + company.id)
        .then(response => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The company has been ', { crud: i18n.t('deletedE') }),
              variant: 'success'
            },
          })

          commit('REMOVE_COMPANY', company)
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.status == 401) {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: i18n.t('UnableToDelete'),
                icon: 'times',
                text: i18n.t('This company is linked to invoices'),
                variant: 'danger'
              },
            })
          }
          reject(error)
        })
    })
  }

}