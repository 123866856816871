export const togglePasswordVisibility = {
  data () {
    return {
      passwordFieldType: 'password',
    }
  },
  methods: {
    togglePasswordVisibility () {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
  },
}

export const heightTransition = {
  /*

    HowTo:
      1. Add dynamic style to element and set style as `trHeight`
      2. Set transition speed using `transition: 0.35s height;` <= you can use appropriate value;
      3. Optionally you can set `overflow: hidden;` to hide element overflow while height is animated.
      4. Set initial height using `trSetHeight` before any operation. [mounted hook is recommended - You can use `ref` for dynamic contents]
      5. Toggle height using height operations 🍻
      6. Toggle usage of $nextTick for height operations is any issue occur [experimental] 🔬

  */

  data() {
    return {
      trHeight: null,
    }
  },
  methods: {
    trAddHeight(val) {
      // Add height to existing height
      // Usage: Where new element is append or more height is added (e.g. list append)

      /* Assumes:
       - Height is assigned and is `String`
       - Incoming value is valid number in `Number` or `String`
      */
      const heightValue = Number(this.trHeight.substring(0, this.trHeight.length - 2))
      this.trHeight = `${heightValue + Number(val)}px`
    },
    trTrimHeight(val) {
      // Remove height from existing height
      // Usage: Where new element is removed or height is remove (e.g. list pop/ele remove)

      /* Assumes:
       - Height is assigned and is `String`
       - Incoming value is valid number in `Number` or `String`
      */
      const heightValue = Number(this.trHeight.substring(0, this.trHeight.length - 2))
      this.trHeight = `${heightValue - Number(val)}px`
    },
    trSetHeight(val) {
      // Set height
      // Usage: Mostly for assigning initial value from mounted hook

      /* Assumes:
       - Height is not assigned and what to assign for add/remove operation
       - What to set height at something for odd usage
       - Incoming value is valid number in `Number` or `String`
      */
      if (val === null) this.trHeight = 'auto'
      else this.trHeight = `${Number(val)}px`
    },
  },
}

export const _ = null

export const cleanEntityToSend = async (entity, params) => {
  let clonedEntity = JSON.parse(JSON.stringify(entity))

  delete clonedEntity.id
  // delete clonedEntity.logs

  await performEntityRecursive(clonedEntity, params)

  // console.log(clonedEntity)

  return clonedEntity
}

const performEntityRecursive = async (entity, params, parent = '') => {
  for (let [key, value] of Object.entries(entity)) {
    if (Array.isArray(value)) {
      // console.log('Is array : ', value)
      if (await inRules(key)) {
        delete entity[key]
      } else {
        for (const valueArray of entity[key]) {
          let index = entity[key].indexOf(valueArray)
          if (inParams(params, key) == 'entity') {
            // Array of entities
            if (valueArray.id !== undefined) {
              entity[key][index] = valueArray.id
            }
          } else if (inParams(params, key) == 'collection') {
            if (valueArray.id !== undefined) {
              delete entity[key][index].id
            }
            await performEntityRecursive(
              valueArray,
              params,
              key
            )
          } else if (await inRules(key)) {
            delete entity[key]
          }
        }
      }
    } else if (value != null) {
      // console.log('!= null : ', value)
      let inParam = inParams(params, key)
      // console.log('In params ?', inParam)

      if (inParam != null) {
        if (inParam == 'entity') {
          if (entity[key].id !== undefined) {
            entity[key] = entity[key].id
          }
        } else if (inParam == 'collection') {
          if (entity[key].id !== undefined) {
            delete entity[key].id
          }
          await performEntityRecursive(
            entity[key],
            params
          )
        }
      }

      if (
        value
          .toString()
          .match(
            /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:Z|(\+|-)([\d|:]*))?(?:.\d{0,3}Z)?$/
          )
      ) {
        // console.log("Is date !")
        entity[key] = moment(value).format(
          'YYYY-MM-DD HH:mm'
        )
      }

      if (await inRules(key)) {
        delete entity[key]
      }
    } else {
      if (await inRules(key)) {
        delete entity[key]
      }
    }
  }
}

const inRules = async (key) => {
  return (key.startsWith('_') || key == 'logs' || key == 'createdAt' || key == 'createdBy')
}

const inParams = (params, key) => {
  for (let [paramType, paramValue] of
    Object.entries(params)) {
    if (paramValue.indexOf(key) != -1) {
      return paramType
    }
  }
  return null
}

const moment = require('moment')
