import api from '@/libs/axios'
import { cleanEntityToSend } from '../../utils/form'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'
import i18n from '@/libs/i18n'

export default {
  getArticles ({ commit }) {
    return new Promise((resolve, reject) => {
      api.get('/articles')
        .then(response => {
          commit('SET_ARTICLES', response.data)
          resolve(response)
        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  async addArticle ({ commit, state }, article) {
    return await cleanEntityToSend(article, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/articles', clonedEntityToSend)
            .then(response => {
              commit('ADD_ARTICLE', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The article has been ', {crud: i18n.t('created')}),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateArticle ({ commit, state }, article) {
    return await cleanEntityToSend(article, state.params)
      .then(async clonedEntityToSend => {

        return new Promise((resolve, reject) => {
          api.put('/articles/' + article.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_ARTICLE', response.data)
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The article has been ', {crud: i18n.t('edited')}),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  // removeArticle ({ commit }, article) {
  //   return new Promise((resolve, reject) => {
  //     api.delete('/articles/' + article.id)
  //       .then(response => {
  //         commit('REMOVE_ARTICLE', article)
  //         resolve(response)
  //       })
  //       .catch(error => {
  //         console.log('Error', error)
  //         reject(error)
  //       })
  //   })
  // }

}