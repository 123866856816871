import state from './eventState';
import mutations from './eventMutations';
import actions from './eventActions';
import getters from "./eventGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}