import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/utils/utils'
import store from '@/store'

import accounting from './routes/accounting'
import accountSettings from './routes/accountSettings'
import contacts from './routes/contacts'
import dashboard from './routes/dashboard'
import humanResources from './routes/humanResources'
import indicators from './routes/indicators'
import pages from './routes/pages'
import purchases from './routes/purchases'
import sales from './routes/sales'
import support from './routes/support'
import warehouse from './routes/warehouse'
import workflows from './routes/workflows'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'home' } },
    ...accounting,
    ...accountSettings,
    ...contacts,
    ...dashboard,
    ...indicators,
    ...pages,
    ...purchases,
    ...sales,
    ...workflows,
    ...humanResources,
    ...support,
    ...warehouse,
    {
      path: '*',
      redirect: { name: 'error' },
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  const userData = getUserData()
  let disconectDateTime = null
  if (userData != null) {
    disconectDateTime = userData.expiredToken.date
  }
  const moment = require('moment')

  if ((isLoggedIn == false || isLoggedIn == null) &&
    !['auth-login',
      'auth-forgot-password',
      'auth-reset-password',
      'auth-validate-registration'].includes(to.name)) {
    return next({ name: 'auth-login', query: { redirect: to.path } })
  } else {
    if (moment().isAfter(moment(disconectDateTime))) {
      store.dispatch('auth/logout')
      next({ name: 'auth-login', query: { redirect: to.path } })
    } else if (!canNavigate(to)) {
      if (isLoggedIn) return next({ name: 'misc-not-authorized' })
      else return next({ name: 'auth-login', query: { redirect: to.path } })
    }
  }

  // console.log(to)
  // console.log(router)
  // console.log(router.history)
  // console.log(router.history.current)
  // console.log(router.history.current.query.redirect)

  // if (typeof router.history.current.query.redirect != 'undefined') {
  //   // router.push(router.history.current.query.redirect)
  //   // next(router.history.current.query.redirect)
  // }

  // Redirect if logged in
  // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
  //   next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  // }

  return next()
})

router.afterEach(() => {
  // console.log(router)
  // console.log(router.history._startLocation)
  // router.push(router.history._startLocation)
  // console.log(JSON.stringify(router))
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
