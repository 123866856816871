import state from './documentState';
import mutations from './documentMutations';
import actions from './documentActions';
import getters from "./documentGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}