import state from './vatState'
import mutations from './vatMutations'
import actions from './vatActions'
import getters from './vatGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}