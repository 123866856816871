export default {
  SET_ADDRESSES (state, addresses) {
    state.addresses = addresses
  },
  ADD_ADDRESS (state, address) {
    state.addresses.push(address)
  },
  UPDATE_ADDRESS(state, address) {
    let index = state.addresses.findIndex(i => i.id === address.id)
    state.addresses.splice(index, 1, address)
  },
  REMOVE_ADDRESS (state, address) {
    state.addresses = state.addresses.filter(item => item.id != address.id)

    // let index = state.addresses.findIndex(i => i.id === address.id)
    // state.addresses.splice(index, 1)
  },
}