import state from './outgoingInvoiceState';
import mutations from './outgoingInvoiceMutations';
import actions from './outgoingInvoiceActions';
import getters from "./outgoingInvoiceGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}