import state from './incomingInvoiceState';
import mutations from './incomingInvoiceMutations';
import actions from './incomingInvoiceActions';
import getters from "./incomingInvoiceGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}