import state from './paymentMethodState'
import mutations from './paymentMethodMutations'
import actions from './paymentMethodActions'
import getters from './paymentMethodGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}