export default {
  SET_USERS (state, users) {
    state.users = users
  },
  ADD_USER (state, user) {
    state.users.push(user)
  },
  UPDATE_USER(state, user) {
    let index = state.users.findIndex(i => i.id === user.id)
    state.users.splice(index, 1, user)
  },
  REMOVE_USER (state, user) {
    state.users = state.users.filter(item => item.id != user.id)

    // let index = state.users.findIndex(i => i.id === user.id)
    // state.users.splice(index, 1)
  },
}