export default [
  // Tickets
  {
    path: '/support',
    name: 'Tickets',
    component: () => import('../../views/support/tickets/Tickets'),
    meta: {
      resource: 'Tickets',
      // action: 'read',
    }
  },

]
