import api from '@/libs/axios'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'
import i18n from '@/libs/i18n'
import moment from 'moment'

export default {
  async recognizeDocument ({ commit, state }, [document, type = null]) {
    Vue.$toast({
      component: ToastificationContent,
      props: {
        title: capitalize(i18n.t('recognition')),
        icon: 'search',
        text: capitalize(i18n.t('recognition in progress')),
        variant: 'warning'
      },
    })

    return new Promise((resolve, reject) => {
      api.post('/documents/' + document.id + '/recognize', { type: type })
        .then(response => {

          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('recognition')),
              icon: 'check',
              text: capitalize(i18n.t('recognition completed')),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },
  generateExcelDetailRevenue ({ commit, state }, monthString) {
    api.post('/indicators/excelDetailRevenue', { month: monthString + '-01' })
      .then(response => {

        let link = document.createElement('a')
        link.href = response.data
        link.download = moment(monthString + '01').format('MMMM YYYY')
        link.click()
      })
      .catch(error => {
        console.log('Error', error)
      })
  },
  generateLiaisonForm ({ commit, state }, monthString) {
    api.post('/indicators/excelLiaisonForm', { month: monthString + '-01' })
      .then(response => {

        let link = document.createElement('a')
        link.href = response.data
        link.download = moment(monthString + '01').format('MMMM YYYY')
        link.click()
      })
      .catch(error => {
        console.log('Error', error)
      })
  },
  generatePdfDetailPurchases ({ commit, state }, monthString) {
    api.post('/indicators/pdfDetailPurchases', { month: monthString + '-01' })
      .then(response => {

        let link = document.createElement('a')
        link.href = response.data
        link.target = '_blank'
        link.download = capitalize(i18n.t('purchases:')) + ' ' + moment(monthString + '01').format('MMMM YYYY')
        link.click()
      })
      .catch(error => {
        console.log('Error', error)
      })
  },
  generatePdfDetailSales ({ commit, state }, monthString) {
    api.post('/indicators/pdfDetailSales', { month: monthString + '-01' })
      .then(response => {

        let link = document.createElement('a')
        link.href = response.data
        link.target = '_blank'
        link.download = capitalize(i18n.t('sales:')) + ' ' + moment(monthString + '01').format('MMMM YYYY')
        link.click()
      })
      .catch(error => {
        console.log('Error', error)
      })
  }

}