import store from '@/store'
import { getUserData, eventToCalendarEvent, getRoute } from '../../utils/utils'

export default {

  getCalendars ({ commit, state }, databaseEvents) {

    if (typeof databaseEvents != 'undefined') {
      let selectedCalendars = []
      let events = []

      if (databaseEvents != null) {
        databaseEvents.forEach(databaseEvent => {
          events.push(eventToCalendarEvent(databaseEvent))

        })
        selectedCalendars.push(getUserData().firstName + ' ' + getUserData().name)
      }
      commit('SET_SELECTED_CALENDARS', selectedCalendars)
      commit('SET_EVENTS', events)
    } else {
      store.dispatch('event/getEvents')
        .then(response => {

          let selectedCalendars = []
          let events = []
          response.data.forEach(event => {
            events.push(eventToCalendarEvent(event))
          })
          selectedCalendars.push(getUserData().firstName + ' ' + getUserData().name)

          commit('SET_SELECTED_CALENDARS', selectedCalendars)
          commit('SET_EVENTS', events)
        })
    }

  },
}