import api from '@/libs/axios'
import { cleanEntity } from '@/utils/form'
import { capitalize } from '@/utils/filter'
import i18n from '@/libs/i18n'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'

export default {
  getAddressByGPS ({ commit }, addressString) {
    return new Promise((resolve, reject) => {
      api.get('/addresses/byGPS', { params: { addressString: addressString } })
        .then(response => {

          if (response.data.city == null || response.data.zipCode == null) {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: capitalize(i18n.t('warning')),
                icon: 'exclamation-triangle',
                text: i18n.t('AddressNotFound'),
                variant: 'warning'
              },
            })
          }

          resolve(response)
        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  getAddressesByQuery ({ commit }, { addressString, cityOnly }) {
    return new Promise((resolve, reject) => {
      api.get('/addresses/byQuery', { params: { addressString: addressString, cityOnly: cityOnly } })
        .then(response => {
          let output = response.data.filter(ad => {
            if (cityOnly) {
              return ad.street == null && ad.city != null
            } else {
              return ad.street != null && ad.city != null
            }
          })

          if (cityOnly) {
            // Distinct
            output = [...new Map(output.map(o => [o.city, o])).values()]
          }

          resolve(output)
        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  // getAddresses ({ commit }) {
  //   return new Promise((resolve, reject) => {
  //     api.get('/addresses')
  //       .then(response => {
  //         commit('SET_ADDRESSES', response.data)
  //         resolve(response)
  //       })
  //       .catch(err => {
  //         console.log('Error', err)
  //         reject(err)
  //       })
  //   })
  // },
  // async addAddress ({ commit, state }, address) {
  //   if (!('roles' in address)) {
  //     address.roles = []
  //   }
  //
  //   return await global.cleanEntity(address, state.params)
  //     .then(clonedEntity => {
  //
  //       return new Promise((resolve, reject) => {
  //         api.post('/addresses', clonedEntity)
  //           .then(response => {
  //             if (response.data != 'exist') {
  //               commit('ADD_ADDRESS', response.data)
  //             }
  //             resolve(response)
  //           })
  //           .catch(error => {
  //             console.log('Error', error)
  //             reject(error)
  //           })
  //       })
  //
  //     })
  //     .catch(error => {
  //       console.log('Error', error)
  //       reject(error)
  //     })
  // },
  // async updateAddress ({ commit, state }, address) {
  //   return await global.cleanEntity(address, state.params)
  //     .then(clonedEntity => {
  //
  //       return new Promise((resolve, reject) => {
  //         api.put('/addresses/' + address.id, clonedEntity)
  //           .then(response => {
  //             commit('UPDATE_ADDRESS', response.data)
  //             resolve(response)
  //           })
  //           .catch(error => {
  //             console.log('Error', error)
  //             reject(error)
  //           })
  //       })
  //
  //     })
  //     .catch(error => {
  //       console.log('Error', error)
  //       reject(error)
  //     })
  // },
  // removeAddress ({ commit }, address) {
  //   return new Promise((resolve, reject) => {
  //     api.delete('/addresses/' + address.id)
  //       .then(response => {
  //         commit('REMOVE_ADDRESS', address)
  //         resolve(response)
  //       })
  //       .catch(error => {
  //         console.log('Error', error)
  //         reject(error)
  //       })
  //   })
  // }

}