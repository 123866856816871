export default {
  tickets: [],
  emptyTicket: {
    files: [],
    priority: 'Normal',
    type: 'Issue',
    status: 'New'
  },
  params: {
    entity: [
      'files'
    ],
    collection: []
  }
}