import moment from 'moment'

export default {
  bankAccounts: [],
  emptyBankAccount: {},
  params: {
    entity: [
      'bankOperations'
    ],
    collection: []
  },
  banks: [
    {
      title: 'LCL',
      value: 'LCL'
    },
    {
      title: 'CA',
      value: 'CA'
    },

  ]
}