import api from '@/libs/axios'
import { cleanEntityToSend } from '../../utils/form'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'
import i18n from '@/libs/i18n'

export default {
  getIncomingInvoices ({ commit }) {
    return new Promise((resolve, reject) => {
      api.get('/incomingInvoices')
        .then(response => {
          commit('SET_INCOMINGINVOICES', response.data)
          resolve(response)
        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  async addIncomingInvoice ({ commit, state }, incomingInvoice) {
    return await cleanEntityToSend(incomingInvoice, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/incomingInvoices', clonedEntityToSend)
            .then(response => {
              commit('ADD_INCOMINGINVOICE', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The incoming invoice has been ', { crud: i18n.t('createdE') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateIncomingInvoice ({ commit, state }, incomingInvoice) {
    return await cleanEntityToSend(incomingInvoice, state.params)
      .then(async clonedEntityToSend => {

        return new Promise((resolve, reject) => {
          api.put('/incomingInvoices/' + incomingInvoice.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_INCOMINGINVOICE', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The incoming invoice has been ', { crud: i18n.t('editedE') }),
                  variant: 'success'
                },
              })

              resolve(response)
              // resolve(JSON.parse(JSON.stringify(response)))
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeIncomingInvoice ({ commit }, incomingInvoice) {
    return new Promise((resolve, reject) => {
      api.delete('/incomingInvoices/' + incomingInvoice.id)
        .then(response => {
          commit('REMOVE_INCOMINGINVOICE', incomingInvoice)

          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The incoming invoice has been ', { crud: i18n.t('removedE') }),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },

}