export default {
  currentBreakPoint: state => {
    // let themeBreakpoints = {
    //   xs: 0,
    //   sm: 540,
    //   md: 720,
    //   lg: 960,
    //   xl: 1140,
    //   xxl: 1440
    // }
    const { windowWidth } = state
    if (windowWidth >= state.breakpoints.xl) return 'xl'
    if (windowWidth >= state.breakpoints.lg) return 'lg'
    if (windowWidth >= state.breakpoints.md) return 'md'
    if (windowWidth >= state.breakpoints.sm) return 'sm'
    return 'xs'
  },
  apiURL: (state) => state.appAPIUrl
}