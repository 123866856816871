export default [
  {
    path: '/dashboard',
    name: 'home',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      resource: 'Dashboard',
      // action: 'read',
    }
  },
  // {
  //   path: '/dashboard/new',
  //   name: 'new dashboard',
  //   component: () => import('@/views/dashboard/New.vue'),
  // },
]
