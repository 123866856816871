import api from '@/libs/axios'
import { cleanEntityToSend } from '../../utils/form'
import { eventToCalendarEvent} from '../../utils/utils'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'
import i18n from '@/libs/i18n'
import store from '@/store'

export default {
  getEvents ({ commit }) {
    return new Promise((resolve, reject) => {
      api.get('/events')
        .then(response => {
          commit('SET_EVENTS', response.data)
          resolve(response)
        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  async addEvent ({ commit, state }, event) {
    return await cleanEntityToSend(event, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/events', clonedEntityToSend)
            .then(response => {
              commit('ADD_EVENT', response.data)
              store.commit('calendar/ADD_EVENT', eventToCalendarEvent(response.data))

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The event has been ', { crud: i18n.t('created') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateEvent ({ commit, state }, event) {
    return await cleanEntityToSend(event, state.params)
      .then(async clonedEntityToSend => {

        return new Promise((resolve, reject) => {
          api.put('/events/' + event.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_EVENT', response.data)
              store.commit('calendar/UPDATE_EVENT', eventToCalendarEvent(response.data))

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The event has been ', { crud: i18n.t('edited') }),
                  variant: 'success'
                },
              })

              resolve(response)
              // resolve(JSON.parse(JSON.stringify(response)))
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeEvent ({ commit }, event) {
    return new Promise((resolve, reject) => {
      api.delete('/events/' + event.id)
        .then(response => {
          commit('REMOVE_EVENT', event)

          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The event has been ', { crud: i18n.t('removed') }),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },

}