export default {
  getCompanies: (state) => {
    // console.log(withMainCompany)
    return state.companies
  },
  getCompaniesWithoutMainCompany: (state) => {
    return state.companies.filter(company => company.id != 1)
  },
  getMyCompany: (state) => {
    return state.companies.find(company => company.id === 1)
  },
  getCompany: (state) => (id) => {
    return state.companies.find(company => company.id === parseInt(id))
  },
  getEmptyCompany: (state) => JSON.parse(JSON.stringify(state.emptyCompany)),
  getEmployees: (state) => {
    return state.companies.filter(company => company.enabled && company.calculatedCurrentContract)
  },
  getEmployeesAndPrevious: (state) => {
    return state.companies.filter(company => company.company.id == 1)
  },
  // getMe: (state) => {
  //   return state.companies.filter(company => JSON.parse(localStorage.getItem('companyInfo')).id)
  // },
}