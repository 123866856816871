import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import address from './address/address'
import app from './app/app'
import article from './article/article'
import auth from './auth/auth'
import bankAccount from './bankAccount/bankAccount'
import bankOperation from './bankOperation/bankOperation'
import bankStatement from './bankStatement/bankStatement'
import calendar from './calendar/calendar'
import company from './company/company'
import document from './document/document'
import employeeContractType from './employeeContractType/employeeContractType'
import event from './event/event'
import incomingInvoice from './incomingInvoice/incomingInvoice'
import order from './order/order'
import outgoingInvoice from './outgoingInvoice/outgoingInvoice'
import paymentMethod from './paymentMethod/paymentMethod'
import paymentTerm from './paymentTerm/paymentTerm'
import offer from './offer/offer'
import salary from './salary/salary'
import socialSecurityContribution from './socialSecurityContribution/socialSecurityContribution'
import task from './task/task'
import ticket from './ticket/ticket'
import user from './user/user'
import vat from './vat/vat'
import workflow from './workflow/workflow'

export default new Vuex.Store({
  modules: {
    address: address,
    app: app,
    article: article,
    auth: auth,
    bankAccount: bankAccount,
    bankOperation: bankOperation,
    bankStatement: bankStatement,
    calendar: calendar,
    company: company,
    document: document,
    employeeContractType: employeeContractType,
    event: event,
    incomingInvoice: incomingInvoice,
    order: order,
    outgoingInvoice: outgoingInvoice,
    paymentMethod: paymentMethod,
    paymentTerm: paymentTerm,
    offer: offer,
    salary: salary,
    socialSecurityContribution: socialSecurityContribution,
    task: task,
    ticket: ticket,
    user: user,
    vat: vat,
    workflow: workflow
  }
})