import moment from 'moment'

export default {
  getIncomingInvoices: (state) => state.incomingInvoices,
  getIncomingInvoice: (state) => (id) => {
    return state.incomingInvoices.find(incomingInvoice => incomingInvoice.id === parseInt(id))
  },
  getEmptyIncomingInvoice: (state) => JSON.parse(JSON.stringify(state.emptyIncomingInvoice)),
  incomingInvoicesNotCompletelyPaid: (state) => {
    return state.incomingInvoices.filter(incomingInvoice => incomingInvoice._paymentStatus != "Paid")
  },
  supplierReceivable: (state) => {
    return state.incomingInvoices.filter(incomingInvoice => {
      return incomingInvoice._paymentStatus != 'Paid' &&
        moment(incomingInvoice.paymentDate) < moment()
    })
  },
  getIncomingInvoicesBetweenDates: (state) => (dates) => {
    return state.incomingInvoices.filter(ii => dates.start <= moment(ii.billingDate) && moment(ii.billingDate) <= dates.end)
  }
}