import api from '@/libs/axios'

export default {
  getEmployeeContractTypes ({ commit }) {
    return new Promise((resolve, reject) => {
      api.get('/employeeContractTypes')
        .then(response => {
          commit('SET_EMPLOYEECONTRACTTYPES', response.data)
          resolve(response)
        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },

}