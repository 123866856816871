import i18n from '@/libs/i18n'

export default {
  SET_EVENTS (state, events) {
    state.events = events
  },
  ADD_EVENT (state, event) {
    state.events.push(event)
  },
  UPDATE_EVENT(state, event) {
    let index = state.events.findIndex(i => i.id == event.id)
    state.events.splice(index, 1, JSON.parse(JSON.stringify(event)))
  },
  REMOVE_EVENT (state, event) {
    state.events = state.events.filter(item => item.id != event.id)

    // let index = state.events.findIndex(i => i.id === event.id)
    // state.events.splice(index, 1)
  },
}