import i18n from '@/libs/i18n'

const translateTerms = (outgoingInvoice) => {
  outgoingInvoice.paymentMethod.name = i18n.t(outgoingInvoice.paymentMethod.name)
  outgoingInvoice.paymentTerm.term = i18n.t(outgoingInvoice.paymentTerm.term)
}

export default {
  SET_OUTGOINGINVOICES (state, outgoingInvoices) {
    outgoingInvoices.forEach(oi => {
      translateTerms(oi)
      // oi.paymentMethod.name = i18n.t(oi.paymentMethod.name)
      // oi.paymentTerm.term = i18n.t(oi.paymentTerm.term)
    })

    state.outgoingInvoices = outgoingInvoices
  },
  ADD_OUTGOINGINVOICE (state, outgoingInvoice) {
    translateTerms(outgoingInvoice)

    state.outgoingInvoices.push(outgoingInvoice)
  },
  UPDATE_OUTGOINGINVOICE(state, outgoingInvoice) {
    translateTerms(outgoingInvoice)

    let index = state.outgoingInvoices.findIndex(i => i.id == outgoingInvoice.id)
    state.outgoingInvoices.splice(index, 1, JSON.parse(JSON.stringify(outgoingInvoice)))
  },
  REMOVE_OUTGOINGINVOICE (state, outgoingInvoice) {
    state.outgoingInvoices = state.outgoingInvoices.filter(item => item.id != outgoingInvoice.id)

    // let index = state.outgoingInvoices.findIndex(i => i.id === outgoingInvoice.id)
    // state.outgoingInvoices.splice(index, 1)
  },
}