import state from './salaryState';
import mutations from './salaryMutations';
import actions from './salaryActions';
import getters from "./salaryGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}