// import i18n from '@/libs/i18n'

export default {
  // SET_DOCUMENTS (state, documents) {
  //   documents.forEach(oi => {
  //     oi.paymentMethod.name = i18n.t(oi.paymentMethod.name)
  //     oi.paymentTerm.term = i18n.t(oi.paymentTerm.term)
  //   })
  //
  //   state.documents = documents
  // },
  // ADD_DOCUMENT (state, document) {
  //   state.documents.push(document)
  // },
  // UPDATE_DOCUMENT(state, document) {
  //   let index = state.documents.findIndex(i => i.id == document.id)
  //   state.documents.splice(index, 1, JSON.parse(JSON.stringify(document)))
  // },
  // REMOVE_DOCUMENT (state, document) {
  //   state.documents = state.documents.filter(item => item.id != document.id)
  //
  //   // let index = state.documents.findIndex(i => i.id === document.id)
  //   // state.documents.splice(index, 1)
  // },
}