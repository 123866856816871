import i18n from '@/libs/i18n'

export default {
  SET_BANKOPERATIONS (state, bankOperations) {
    bankOperations.forEach(b => {
      b.paymentMethod.name = i18n.t(b.paymentMethod.name)
    })

    state.bankOperations = bankOperations
  },
  ADD_BANKOPERATION (state, bankOperation) {
    state.bankOperations.push(bankOperation)
  },
  UPDATE_BANKOPERATION (state, bankOperation) {
    let index = state.bankOperations.findIndex(i => i.id == bankOperation.id)
    state.bankOperations.splice(index, 1, JSON.parse(JSON.stringify(bankOperation)))
  },
  REMOVE_BANKOPERATION (state, bankOperation) {
    state.bankOperations = state.bankOperations.filter(item => item.id != bankOperation.id)

    // let index = state.bankOperations.findIndex(i => i.id === bankOperation.id)
    // state.bankOperations.splice(index, 1)
  },
}