import moment from 'moment'

export default {
  tasks: [
    // {
    //   id: 1,
    //   title: 'Task 1',
    //   isDone: false,
    //   deadline: moment().toISOString(),
    //   reminder: null,
    //   // repetition: null,
    //   repetition: {
    //     'days': [
    //       'tuesday',
    //       'thursday'
    //     ],
    //     'quantity': '1',
    //     'period': 'weeks',
    //     '_display': 'Toutes les semaines<br /><small>Mardi, jeudi</small>'
    //   }
    // },
    // {
    //   id: 2,
    //   title: 'Task 2',
    //   isDone: false,
    //   deadline: null,
    //   repetition: null
    // }
  ],
  emptyTask: {
    'documents': [],
    'steps': []
  },
  params: {
    entity: [
      'phase',
      'workflow',
      'documents'
    ],
    collection: [
      'repetition',
      'steps'
    ]
  }
}