export default {
  SET_COMPANIES (state, companies) {
    state.companies = companies
  },
  ADD_COMPANY (state, company) {
    state.companies.push(company)
  },
  UPDATE_COMPANY(state, company) {
    let index = state.companies.findIndex(i => i.id === company.id)
    state.companies.splice(index, 1, company)
  },
  REMOVE_COMPANY (state, company) {
    state.companies = state.companies.filter(item => item.id != company.id)

    // let index = state.companies.findIndex(i => i.id === company.id)
    // state.companies.splice(index, 1)
  },
}