export default {
  getCountries: (state) => state.countries,
  getCountry: (state) => (countryCode) => {
    return state.countries.find(country => country.code === countryCode).country
  },
  // getAddresses: (state) => state.addresses,
  // getAddress: (state) => (id) => {
  //   return state.addresses.find(address => address.id === id)
  // },
  // getEmptyAddress: (state) => state.emptyAddress,
  // getEmployees: (state) => {
  //   return state.addresses.filter(address => address.enabled && address.calculatedCurrentContract)
  // },
  // getEmployeesAndPrevious: (state) => {
  //   return state.addresses.filter(address => address.company.id == 1)
  // },
}