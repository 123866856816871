import state from './addressState';
import mutations from './addressMutations';
import actions from './addressActions';
import getters from "./addressGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}